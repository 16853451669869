import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import AuthLayout from 'bh-mod/layouts/Auth'
import Splash from '@/layouts/Splash'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					meta: {
						title: 'Forms',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/forms',
					meta: {
						title: 'Forms',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/surveys',
					meta: {
						title: 'Forms',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/walk-in',
					meta: {
						title: 'Forms',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/worksheets',
					meta: {
						title: 'Worksheets',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/index'),
				},
				{
					path: '/add',
					meta: {
						title: 'Add New Form',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/EditForm'),
				},
				{
					path: '/edit/:id',
					meta: {
						title: 'Edit New Form',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/EditForm'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/',
			redirect: '/v3',
			component: AuthLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				{
					path: '/preview/:formID',
					meta: {
						title: 'Preview',
						sidebar: false,
						dataRequired: true
					},
					component: () =>
						import('./components/forms/formPreview'),
				},
			]
		},
		{
			path: '/settings',
			redirect: '/settings',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [{
				path: '/settings',
				meta: {
					title: 'Email Settings',
					sidebar: true,
					dataRequired: true
				},
				component: () =>
					import('./views/app/settings'),

			}]

		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Setup Forms',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),

			}]
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
})

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'forms')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && (Object.keys(store.state.forms.allForms).length || store.state.forms.allSettings.userApp.options.seenIntro)) {
			return next('/')
		}
		if (dataRequired && !Object.keys(store.state.forms.allForms).length && !store.state.forms.getStarted && !store.state.forms.allSettings.userApp.options.seenIntro) {
			return next('/setup')
		}
	}
	store.commit('PAGE_BUTTONS', [])

	let isPremiumPackage = store && store.getters && store.getters.isPremiumPackage || false
	if (to.path.startsWith("/worksheets") && !isPremiumPackage) {
		// restricted reservations page to only lowrise and highrise premium packages only
		return next("/");
	}
	next()
})


export default router
